import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { EN_LOCALE, getLocalStorageLocale } from '../../util/types';
import { camelize } from './../../util/string';

const LANDING_PAGE = 'landing-page';

export let ASSET_NAME = LANDING_PAGE;

export const loadData = (params, search, config) => dispatch => {
  const { locale } = config.localization;

  const localStorageLocale = getLocalStorageLocale();
  if (localStorageLocale === EN_LOCALE) {
    ASSET_NAME = LANDING_PAGE + '-' + localStorageLocale;
  }

  const pageAsset = { [camelize(ASSET_NAME)]: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
