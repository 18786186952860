import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { camelize } from '../../util/string';
import { EN_LOCALE, getLocalStorageLocale } from '../../util/types';

const TERMS_OF_SERVICE = 'terms-of-service';

export let ASSET_NAME;

export const loadData = (params, search, config) => dispatch => {
  // const { locale } = config.localization;
  const locale = getLocalStorageLocale();
  if (locale === EN_LOCALE) {
    ASSET_NAME = TERMS_OF_SERVICE + '-' + locale;
  } else {
    ASSET_NAME = TERMS_OF_SERVICE;
  }

  const pageAsset = { [camelize(ASSET_NAME)]: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
